import * as React from "react"
import { graphql, Link } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { Row, Col, Container, Card } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = ({data}) => (
  <Layout>
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
    {/* {data.caseStudies.edges.map(({node}) => (
          <div key={node.id} >
            <GatsbyImage image={getImage(node.frontmatter.Image01.childImageSharp)} alt={node.frontmatter.image1alt}  />
            
            <h2>{node.frontmatter.title}</h2>
            
          </div>
        ))} */}

<Container>
          <h1>Case Studies</h1>
          <Row className="mb-3">
          {data.caseStudies.edges.map(({node}) => (
          <Col lg={4} key={node.id}>

            <Card>
              <Link to={node.fields.slug}>
              <GatsbyImage image={getImage(node.frontmatter.Image01.childImageSharp)} alt={node.frontmatter.image1alt} className="card-img-top"  />
                {/* <Img 
                  fluid={node.frontmatter.Image01.childImageSharp.fluid}
                  alt={node.frontmatter.image1alt}
                  className="card-img-top"
                /> */}
              </Link>
              <Card.Body>
                <Card.Title>
                  <Link to={node.fields.slug}><small>{node.frontmatter.title}</small></Link></Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
        </Row>
          </Container>
  </Layout>
)

export const Head = () => <Seo title="Page two" />

export default SecondPage

export const pageQuery = graphql`
  query {
    caseStudies: allMarkdownRemark(sort: {frontmatter: {title: ASC}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image1alt
            Image01 {
              childImageSharp {
                gatsbyImageData(width: 750, aspectRatio: 1.5)
              }
            }
          }
        }
      }
    }
  }
`